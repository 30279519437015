import React from "react";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";
import amaz from './../../images/amaz.png';
import header1 from './../../images/header2.png';
//TODO Web Template Studio: Add a new link in the NavBar for your page here.
// A skip link is included as an accessibility best practice. For more information visit https://www.w3.org/WAI/WCAG21/Techniques/general/G1.
const NavBar = () => {
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-sm navbar-variant border-bottom justify-content-between" >

         <div className="navbar-nav">

      
        <Link className="nav-item nav-link active" to="/" >
        <p class="text-info"> Home</p> 
        </Link>
     </div>  

          <div classname="row justify-content-center ">
      
           <a href="https://www.amazon.com/s?k=A+pupster%27s+life&ref=nb_sb_noss" target="_blank">   
            <img sizes="75vw"
            srcSet={`${header1} 1000w, ${header1} 1000w, ${header1} 1000w`}
              alt="Currently available on Amazon"></img>
        </a>
          </div>      
         
          <Link className="nav-item nav-link active" to="About" >
        <p class="text-info"> About</p> 
        </Link>
      </nav>
      
      
    </React.Fragment>
  );
}
export default NavBar;
