import React from "react";
import cover from './../../images/cover.png';
import cover250 from './../../images/cover250.png';
import cover500 from './../../images/cover500.png';
import amaz from './../../images/amaz2.png';
import "./main.css";
const Home = () => {
  return <main id="mainContent ">
    
    <div className="container ">
     
      <div classname=" row justify-content-around ">
     
        <img sizes='60vw'
          // style={{ float: 'left' }}
            srcSet={`${cover250} 250w, ${cover500} 500w, ${cover} 850w`}
            alt="Cover Image">
        </img> 
        <p></p>
          <br></br>
           <p> Hi, my name is Pupster Charlie. 
            In my book, you will get to know how I spend each day. I was rescued by my family, and I share each day loving them.  I hope you enjoy reading about me.
            </p>
      <p>A children's rhyme book, fun for all ages, will put a smile on your face :)</p> 
        </div>
      
        <div className="row justify-content-center">
          
        <a href="https://www.amazon.com/s?k=A+pupster%27s+life&ref=nb_sb_noss" target="_blank"> <img 
          src={amaz} alt="Currently available on Amazon"></img></a>
          
          {/* <p> <img src = "cover" alt="Currently Available on Amazon"/></p> */}
               
      </div>
     

    </div>

    
  </main>;
}
export default Home;
