import React from "react";
import styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer} >
      <div className="container-fluid row justify-content-between" >
        {/* <div className="row justify-content-around"> */}
          <div className="col-8 col-md-2">
            <h5 className={styles.title}>A Pupster's Life</h5>
            <p className={styles.description}>Copyright 2020</p>
            
          </div>
          <div className="col-2">
            <ul className="list-unstyled">
              <li>
                <a className={styles.footerlink} href="https://www.cdsngconsulting.com" target="_blank">
                  <h5>CDSNG Consulting LLC <br/></h5>
                  <em>Publishing Company</em>
                </a>
              </li>
              {/* <li>
                <a className={styles.footerlink} href="/">
                  Example Link
                </a>
              </li>
              <li>
                <a className={styles.footerlink} href="/">
                  Example Link
                </a>
              </li> */}
            </ul>
          </div>
        {/* </div> */}
      </div>
    </footer>
  );
}
export default Footer;