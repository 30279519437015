import React from "react";
import backcover from "./../../images/backcover.png";
import backcover250 from "./../../images/backcover250.png";
import backcover500 from "./../../images/backcover500.png";

const About = () => {
  return <main id="mainContent" >
    <div className="container">
      <div className="row justify-content-center mt-5 p-0">
  
        <p>Thank you all for reading A Pupster’s Life.  This book is
          dedicated to our family puppy, Charlie Gandhi.</p>
        <p>Since childhood, I have always been afraid of dogs.  However, after Pupster Charlie came into my life, my perspective changed.  Charlie is a rescue dog which became our emotional support dog. We are so glad she came into our home.</p>
        <p>The best feelings are when arrive home.  Charlie gives us so much love and attention.  She is always excited around us.
          Daily she plays with her toys. She fetches her toy over and over.  When she wants to sleep, she curls up into a ball; right next to me.  The love she brings into our life is incredible.  </p>
          <p>Please enjoy this book with your family. Share the message and let’s increase puppy, dog, and pet lovers all around the world.</p>
    </div>
      <div className="row justify-content-center mt-5 p-0">
        <h3><em>Go get your pet companion now.</em></h3>
      
        <img sizes="70vw"
          srcSet={`${backcover250} 250w, ${backcover500} 500w, ${backcover} 850w`}
          alt="Back Cover">
        </img>
      </div>
      <div className="row justify-content-center mt-5 p-0">
        
        
        <p>Next book in this series is: <strong>Pupster’s Friends....</strong> </p>
      </div>
          <div className="row justify-content-center mt-5 p-0">
          <p> <strong> <em> Contact: <a href="mailto:pupsterslife@gmail.com"> Pupster's email</a></em></strong></p>
           
          </div>
    </div>
  </main>;
}
export default About;
